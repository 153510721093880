<template>
  <div class="calendairo">
    <!-- <vx-card class="mb-4">
      <div class="vx-row">
        <vs-select
          class="vx-col w-full md:w-1/2 mb-3"
          label="Agrupar por"
          placeholder="Franquicias"
        ></vs-select>
        <label for=""></label>
        <vs-select
          class="vx-col w-full md:w-1/2 md:mt-6 mb-3"
          placeholder="Jarris"
        ></vs-select>
      </div>
    </vx-card> -->
    <SimpleCalendar> </SimpleCalendar>
  </div>
</template>

<script>
export default {
  components: {
    SimpleCalendar: () => import('@/components/calendar/SimpleCalendar.vue'),
  },
};
</script>

<style></style>
